<template lang="">
    <Loader v-if="isBusy"/>
  
      <main class="payment-flow">
          <div class="container">
                <button
                  @click.prevent="submit()"
                  type="submit"
                  class="button button--primary"
                >
                  Testar API
                </button>
          </div>
      </main>
  </template>
  <script>
  import SubmitPayment from "@/services/post/submit-payment-service";
  import Loader from "@/presentation/components/Loader.vue";
  
  import { toast } from "vue3-toastify";
  import "vue3-toastify/dist/index.css";
  
  export default {
      name: 'app-teste-api',
  
      data() {
          return {
              isBusy: false,
          }
      },
  
      methods: {
          showError(errorMessage) {
              toast(errorMessage, {
                  autoClose: 6000,
                  type: "error",
              });
          },
  
          showMessage(message) {
              toast(message, {
                  autoClose: 6000,
                  type: "success",
              });
          },
  
          async submit() {
              this.isBusy = true;
  
              const userData = {
                  fullName: "Teste",
                  email: "teste@teste.com",
                  phone: "5511999999999",
                  cpf: "00000000000"
              };
              const companyData = {
                  contractMethod: "open-company",
                  activity: "TI",
                  addressType: "pjzen-address",
                  partnersNumber: "1"
              };
              const paymentData = {
                  holderName: "José da Silva",
                  cardExpirationDate: "12/2030",
                  cardNumber: "5167454851671773",
                  securityCode: "123"
              };
              const subscriptionData = {
                  planId: 370988,
                  productId: 1299545
              };
  
              // REQUEST
              const submitResponse = await SubmitPayment.submit(
                  userData,
                  companyData,
                  paymentData,
                  subscriptionData,
              );
  
              if (!submitResponse || submitResponse.error) {
                  this.showError(submitResponse.error.message);
              } else {
                  this.showMessage('Sucesso!');
              }
  
              this.isBusy = false;
          },
      },
  
      components: { Loader },
  
  }
  </script>
  <style lang="">
      
  </style>