<template lang="">
  <section class="section-default">
    <div class="container">
      <div class="call-to-action">
        <h2 class="call-to-action__title">
          <span class="call-to-action__title__content"
            >Fique tranquilo e deixe <br />a burocracia
          </span>
          <span class="call-to-action__title__content__hightlight"
            ><span class="call-to-action__title__content__hightlight__text"
              >conosco</span
            ></span
          >
        </h2>
        <a
          @click.prevent="$emit('onOpenGetInTouchForm')"
          class="button button--primary"
        >
          Fale com a gente
        </a>

        <img
          src="@/assets/images/svg/home-main_section_line.svg"
          alt="section line"
        />
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "app-call-to-action",

  emits: ['onOpenGetInTouchForm'],
};
</script>
<style lang=""></style>
