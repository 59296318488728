<template lang="">
  <main class="payment-flow">
    <div class="container payment-flow__success">
      <div class="eclipse eclipse--green"></div>

      <header class="payment-flow__header">
        <router-link :to="{ name: 'home' }" class="payment-flow__header__logo">
          <img src="@/assets/images/svg/icons/logo.svg" alt="PJ Zen" />
        </router-link>
      </header>

      <img
        src="@/assets/images/svg/flow-payment-sucess_image.svg"
        alt="Success"
        class="payment-flow__success-image"
      />

      <h2>Pronto!</h2>

      <p>
        Contratação realizada com sucesso. <br />
        Agora deixe tudo com a gente.
      </p>

      <router-link :to="{ name: 'home' }" class="button button--primary">
        Voltar para o site
      </router-link>
    </div>
  </main>
</template>
<script>
export default {

}
</script>
<style lang="">
    
</style>