import axios from "axios";

export default () => {
  const isLocalhost = window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1";

  const baseUrl = isLocalhost
    ? process.env.VUE_APP_CLOUDFUNCTIONS_BASEURL_LOCAL
    : process.env.VUE_APP_CLOUDFUNCTIONS_BASEURL;

  const apiKey = process.env.VUE_APP_FIREBASE_APIKEY;

  const instance = axios.create({
    baseURL: baseUrl,
    headers: {
      "Content-Type": "application/json",
      "Authorization": apiKey,
    }
  });

  return instance;
};
