import api from "@/services/api";
import CryptoJS from 'crypto-js';

export default {
    async submit(
        userData,
        companyData,
        paymentData,
        subscriptionData,
    ) {
        // CRYPTO
        const encryptedKey = process.env.VUE_APP_ENCRYPTED_KEY;
        const userDataEncrypted = CryptoJS.AES.encrypt(JSON.stringify(userData), encryptedKey).toString();
        const companyDataEncrypted = CryptoJS.AES.encrypt(JSON.stringify(companyData), encryptedKey).toString();
        const paymentDataEncrypted = CryptoJS.AES.encrypt(JSON.stringify(paymentData), encryptedKey).toString();
        const subscriptionDataEncrypted = CryptoJS.AES.encrypt(JSON.stringify(subscriptionData), encryptedKey).toString();

        try {
            const response = await api().post(
                "submit",
                {
                    userData: userDataEncrypted,
                    companyData: companyDataEncrypted,
                    paymentData: paymentDataEncrypted,
                    subscriptionData: subscriptionDataEncrypted,
                }
            );

            return response;
        } catch (error) {
            if (error.response && error.response.data) {
                return error.response.data;
            }

            return {
                error: {
                    message: "Ocorreu um erro na operação. Por favor, tente novamente mais tarde ou entre em contato.",
                }
            };
        }
    },
};
