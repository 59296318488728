<template>
  <div v-if="plansList.length > 0" class="plans-section">
    <div v-for="(plan, index) in plansList" :key="index" @click="selectPlan(plan)" :class="[
      { active: selectedPlan != null && selectedPlan.name === plan.name },
      `plans-section__card plans-section__card--${planColor(plan)}`,
    ]">
      <h3>{{ plan.plan_items[0].product.name }}</h3>
      <h4 v-if="plan.plan_items[0].product.pricing_schema.price !== null">
        {{ plan.plan_items[0].product.pricing_schema.short_format }}
      </h4>
      <legend>{{ plan.interval_name }}</legend>

      <a v-if="isInteractive" href="#" @click.prevent
        :class="`button button--primary button--${planColor(plan) || 'orange'}`">
         {{plan.interval_name !=null ? isInteractive ? "SELECIONAR PLANO" : "COMECE AGORA" : ""}} 
      </a>

      <router-link v-else :to="{ name: 'price-page' }"
        :class="`button button--primary button--${planColor(plan) || 'orange'}`">
        {{plan.interval_name !=null ? isInteractive ? "SELECIONAR PLANO" : "COMECE AGORA" : ""}} 
      </router-link>

      <div class="divider"></div>

      <ul class="plans-section__card__list">
        <li v-for="(included, index) in plan.included" :key="index">
          <div> <img src="@/assets/images/svg/icons/icon-check.svg" alt="Icon check" />
            <span>{{ included }}</span>
          </div>
          <div v-if="included == 'Processo de abertura'" class="tooltip">
            <img src="@/assets/images/svg/icons/icon-info.svg" alt="info">
            <span class="tooltip__item">Sem cobrança de taxas e emolumentos para empresas constituídas no município de São
              Paulo, em relação aos demais municípios, realizaremos uma consulta prévia para verificar se haverá algum
              tipo de cobrança no momento da contratação.</span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
// import GetPlansService from "@/services/get-plans-service.js";

import plansList from "@/commons/content/plans.json";

export default {
  name: "app-plans-list",

  data() {
    return {
      plansList: [],

      selectedPlan: null,

      customPlan: {
        name: "Custom",
        status: "active",
        interval_name: "Saiba mais",
        included: [
          "Contabilidade completa",
          "Processo de abertura",
          "Emissão de NFs pela PJzen",
          "Até 2 sócios (Pró-Labore)",
          "Entre 100K e 300K faturamento/mês",
        ],
        plan_items: [
          {
            product: {
              name: "Custom",
              unit: "Reais",
              status: "active",
              pricing_schema: {
                short_format: "On-Demand",
                price: "On-Demand",
              },
            },
          },
        ],
      },
    };
  },

  props: {
    isInteractive: {
      type: Boolean,
      required: true,
    },
    hasCustomPlan: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  async created() {
    this.plansList = plansList.plans;
    this.selectedPlan = plansList.plans[0];

    // this.isBusy = true;
    // const response = await GetPlansService.getPlans();
    // this.isBusy = false;

    // for (let index = 0; index < 3; index++) {
    //   this.plansList.push(response.data.plans[index]);
    // }

    // console.log(response);
  },

  computed: {},

  methods: {
    planColor(plan) {
      switch (plan.name) {
        case "Plano Zen I":
          return "";

        case "Zen Plus":
          return "blue";

        case "Zen Pro":
          return "blue-dark";

        default:
          return "";
      }
    },

    selectPlan(plan) {
      this.selectedPlan = plan;
      this.$emit("onSelectPlan", plan);
    },
  },

  emits: ['onSelectPlan', 'onOpenGetInTouchForm'],

  mounted() {
    this.$emit("onSelectPlan", this.selectedPlan);
  },
};
</script>
<style lang=""></style>
