<template>
  <router-view @onOpenGetInTouchForm="openGetInTouchForm()"></router-view>

  <Modal ref="getInTouchForms" :hasButtonInside="true">
    <GetInTouchForm />
  </Modal>
</template>

<script>
import GetInTouchForm from "@/presentation/modules/GetInTouchForm.vue";
import Modal from "@/presentation/components/Modal.vue";

export default {
  name: "App",

  methods: {
    openGetInTouchForm() {
      this.$refs.getInTouchForms.open();
    },
  },

  emits: ['onOpenGetInTouchForm'],

  components: {
    Modal,
    GetInTouchForm,
  },
};
</script>
