<template>
    <div>
        
    </div>
</template>
<script>
export default {
    name: "app-not-found"
}
</script>
<style lang="">
    
</style>